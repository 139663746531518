var YouLi = YouLi ? YouLi : {};
YouLi.UpgradeSubscription = YouLi.UpgradeSubscription ? YouLi.UpgradeSubscription : {};

(function (YouLi) {
    (function (UpgradeSubscription) {

        YouLi.UpgradeSubscription.ShowUpgradeDialog = function (message, returnURL, showPremiumTrip = false, tripId = "") {
            $("#UpgradeMessage").html(message);

            var upgradeLink = "/account/billingsetup";
            if (returnURL) {
                upgradeLink = YouLi.Common.Utils.AddParametersToURL(upgradeLink, "returnurl=" + returnURL);
            }

            if (tripId) {
                upgradeLink = YouLi.Common.Utils.AddParametersToURL(upgradeLink, "tripid=" + tripId);
            }

            $("#UpgradeLink").attr("href", upgradeLink);

            $("#UpgradeAccountDialogTripPackage").toggleClass("hidden", !showPremiumTrip);
            $("#UpgradeAccountDialogNonTripPackage").toggleClass("hidden", showPremiumTrip);

            $("#UpgradeAccountDialog").modal("show");
        }

        YouLi.UpgradeSubscription.GoToUpgradePage = function (title, message, confirmTitle, confirmMessage, trialExpired, redirectURL, tripId, target) {

            YouLi.Common.UI.ShowLoading();

            // setup to redirect to the billing setup page
            var url = "/account/billingsetup";

            if (message) {
                url = YouLi.Common.Utils.AddParametersToURL(url, "customMessageTitle=" + title);
                url = YouLi.Common.Utils.AddParametersToURL(url, "customMessage=" + message);
            }

            if (confirmTitle !== undefined) {
                url = YouLi.Common.Utils.AddParametersToURL(url, "upgradeconfirmationtitle=" + confirmTitle);
            }

            if (confirmMessage !== undefined) {
                url = YouLi.Common.Utils.AddParametersToURL(url, "upgradeconfirmationmessage=" + confirmMessage);
            }

            if (trialExpired) {
                url = YouLi.Common.Utils.AddParametersToURL(url, "trialexpired=" + trialExpired);
            }

            if (redirectURL) {
                url = YouLi.Common.Utils.AddParametersToURL(url, "returnurl=" + redirectURL);
            }

            if (tripId) {
                url = YouLi.Common.Utils.AddParametersToURL(url, "tripid=" + tripId);
            }

            if (target) {
                YouLi.Common.UI.HideLoading();
                window.open(url, target);
            }
            else
                document.location = url;
        };

    }(YouLi.UpgradeSubscription ? YouLi.UpgradeSubscription : {}));
}(YouLi ? YouLi : {}));